import { GATEWAY_BASE_API_URL } from '@configs/keys';

const urlParamsReplacer = baseUrl => urlString => urlArgs =>
  urlArgs
    ? Object.entries(urlArgs).reduce(
        (targetUrl, [key, value]) => targetUrl.replace(`:${key}`, value),
        `${baseUrl}${urlString}`
      )
    : `${baseUrl}${urlString}`;

const spendUrl = urlParamsReplacer(`${GATEWAY_BASE_API_URL}/corporate-expense`);
const apolloBalanceUrl = urlParamsReplacer(`${GATEWAY_BASE_API_URL}/account`);
const gatewayUrl = urlParamsReplacer(GATEWAY_BASE_API_URL);
const cccGatewayUrl = urlParamsReplacer(
  `${GATEWAY_BASE_API_URL}/corporate-credit-card`
);
const reimbursementGatewayUrl = urlParamsReplacer(
  `${GATEWAY_BASE_API_URL}/corporate-reimbursement`
);
const idpGatewayUrl = urlParamsReplacer(
  `${GATEWAY_BASE_API_URL}/identity-provider`
);
const payrollGatewayUrl = urlParamsReplacer(`${GATEWAY_BASE_API_URL}/payroll`);

const spendApi = {
  bankCodes: spendUrl('/transactions/bank-codes'),
  categories: spendUrl('/transactions/categories'),
  createCategory: spendUrl('/transactions/categories'),
  updateCategory: spendUrl('/transactions/categories/:categoryId'),
  vendors: spendUrl('/transactions/vendors'),
  createVendor: spendUrl('/transactions/vendors'),
  updateVendor: spendUrl('/transactions/vendors/:vendorId'),
  createTransaction: spendUrl('/transactions'),
  createInvoiceTransaction: spendUrl('/invoices'),
  invoicesUploadAttachment: spendUrl('/invoices/upload'),
  invoicesTransactionDetail: spendUrl('/invoices/:transactionId'),
  approveTransactionDetail: spendUrl('/invoices/:transactionId/review/approve'),
  allTransaction: spendUrl('/transactions'),
  detailTransaction: spendUrl('/transactions/detail/:transactionId'),
  deleteTransaction: spendUrl('/accounts/transactions/:transactionId'),
  cancelTransactions: spendUrl('/accounts/transactions/:transactionId/cancel'),
  accountTransactions: spendUrl('/accounts/transactions'),
  approveTransactionSubmission: spendUrl(
    '/accounts/transactions/submission/:transactionId/approve'
  ),
  createTopupPayment: spendUrl('/accounts/transactions/payment-form'),
  rejectTransactions: spendUrl('/accounts/transactions/reject'),
  approveTransactions: spendUrl('/accounts/transactions/approve'),
  pendingTransactions: spendUrl('/transactions/status/pending'),
  uploadAttachment: spendUrl('/transactions/attachment'),
  batchTemplate: spendUrl('/transactions/batch/template/:type'),
  createBatchTransactions: spendUrl('/transactions/batch'),
  createBatchVendors: spendUrl('/transactions/vendors/batch'),
  approvalFlows: spendUrl('/transactions/approval-flows'),
  createApprovalFlow: spendUrl('/transactions/approval-flows'),
  updateApprovalFlow: spendUrl('/transactions/approval-flows/:approvalFlowId'),
  deleteApprovalFlow: spendUrl('/transactions/approval-flows/:approvalFlowId'),
  getDisbursements: spendUrl('/transactions/status/approved'),
  approveDisbursements: spendUrl('/accounts/transactions/disbursement'),
  rejectDisbursements: spendUrl('/accounts/transactions/disbursement/reject'),
  validateAccount: spendUrl('/transactions/validate'),
  checkRTGS: spendUrl('/transactions/rtgs/usage'),
  getVendorSubmissionLink: spendUrl('/vendor/:id/vendor-submission-link'),
  createVendorSubmissionLink: spendUrl('/vendor/vendor-submission-link'),
  getVendorSubmissionLinkByUrlId: spendUrl(
    '/vendor/vendor-submission-link/:urlId'
  ),
  uploadAttachmentByVendor: spendUrl(
    '/vendor/vendor-submission-link/:urlId/attachments'
  ),
  submitTransactionByVendor: spendUrl(
    '/vendor/vendor-submission-link/:urlId/transactions'
  ),
  getPaylaterBalance: spendUrl('/apollo-balance/paylater'),
  getLoanBalance: spendUrl('/apollo-balance/loan'), // TODO: To update based on the backend routing
  updateCategoryChartOfAccounts: spendUrl(
    '/transactions/categories/chart-of-accounts'
  ),
  sendNotification: spendUrl('/accounts/transactions/notification'),
  getTaxTypes: spendUrl('/tax-types'),
  createTaxType: spendUrl('/tax-types')
};

const cardApplicationApi = {
  getApplications: cccGatewayUrl('/card-application'),
  requestCardActivation: cccGatewayUrl('/card-application/activation-request'),
  createApplication: cccGatewayUrl('/card-application'), // create application for existing customer
  cardApplicationSubmit: cccGatewayUrl('/onboard/card-application'), // create application for new customer
  uploadBankForm: cccGatewayUrl('/card-application/bank-form')
};

const cardNotificationApi = {
  getCardNotification: cccGatewayUrl('/card/:id/settings/notifications'),
  toggleCardNotification: cccGatewayUrl('/card/:id/settings/notifications')
};

const cardLimitChangeApi = {
  getList: cccGatewayUrl('/limit-change-request'), // GET
  create: cccGatewayUrl('/limit-change-request') // POST
};

const apolloBalanceApi = {
  downloadBalanceHistory: apolloBalanceUrl('/apollo-balance/ledger/download'),
  balanceHistory: apolloBalanceUrl('/apollo-balance/ledger'),
  balance: apolloBalanceUrl('/apollo-balance')
};

const transactionApi = {
  getAllTransactions: cccGatewayUrl('/transaction'),
  getTransactionDetail: cccGatewayUrl('/transaction/detail/:id'),
  downloadExcel: cccGatewayUrl('/transaction/excel'),
  downloadCSV: cccGatewayUrl('/transaction/csv'),
  report: cccGatewayUrl('/transaction/report/dashboard-url')
};

const employeeApi = {
  getParentCompanyEmployee: idpGatewayUrl('/parent-company/employees'),
  getEmployeeList: idpGatewayUrl('/employee'),
  getEmployeeById: idpGatewayUrl('/employee/:id'),
  getJobPositions: idpGatewayUrl('/job-position'),
  getOrganizations: idpGatewayUrl('/organization'),
  batchEmployee: idpGatewayUrl('/employee/batch'),
  batchUpdateEmployeeBankInformation: idpGatewayUrl(
    '/employee/bulk/bank-information'
  ),
  resignEmployee: idpGatewayUrl('/employee/:companyEmployeeId/resign'),
  getEmployeeProfile: idpGatewayUrl('/profile/employee'),
  getCurrenciesList: idpGatewayUrl('/profile/employee/currencies'),
  downloadBatchEmployeesExport: idpGatewayUrl('/employee/batch/template')
};

const ocrApi = {
  scan: idpGatewayUrl('/ocr/receipts'), //POST
  scanResult: idpGatewayUrl('/ocr/result') //GET
};

const reimbursementApi = {
  getReimbursements: reimbursementGatewayUrl('/reimbursements'),
  getReimbursementDetail: reimbursementGatewayUrl('/reimbursements/:id'),
  getReimbursementActivity: reimbursementGatewayUrl(
    '/reimbursements/:id/journeys'
  ),
  getApprovalHistoryReimbursements: reimbursementGatewayUrl(
    '/approval/history/reimbursements'
  ),
  getSummaryReimbursements: reimbursementGatewayUrl(
    '/summarisation/reimbursements'
  ),
  updateReimbursement: reimbursementGatewayUrl('/reimbursements'),
  downloadExcelReport: reimbursementGatewayUrl('/report/excel'),
  downloadAttachments: reimbursementGatewayUrl('/report/attachment'),
  createTransaction: reimbursementGatewayUrl('/reimbursements'),
  resubmitTransaction: reimbursementGatewayUrl('/reimbursements/resubmit'),
  retryDisbursement: reimbursementGatewayUrl(
    '/reimbursements/retry-disbursement'
  ),
  paymentApproval: reimbursementGatewayUrl('/reimbursements/payment'),
  uploadImages: reimbursementGatewayUrl('/reimbursements/images'),
  addNewAttachments: reimbursementGatewayUrl('/reimbursements/attachments'),
  getAttachments: reimbursementGatewayUrl('/reimbursements/attachments'),
  getCategories: reimbursementGatewayUrl('/categories'),
  createCategories: reimbursementGatewayUrl('/categories'),
  updateCategories: reimbursementGatewayUrl('/categories'),
  getCategoryReimbursementRules: reimbursementGatewayUrl(
    '/categories/:id/reimbursement-rules'
  ),
  createCategoryReimbursementRules: reimbursementGatewayUrl(
    '/categories/:id/reimbursement-rules'
  ),
  updateCategoryReimbursementRules: reimbursementGatewayUrl(
    '/categories/:id/reimbursement-rules'
  ),
  deleteCategoryReimbursementRules: reimbursementGatewayUrl(
    '/categories/:id/reimbursement-rules/:ruleId'
  ),
  getEligibleReimbursedCategories: reimbursementGatewayUrl(
    '/categories/reimbursements'
  ),
  downloadBulkApprovalFlowTemplate: reimbursementGatewayUrl(
    '/approval-flows/batch/template'
  ),
  bulkApprovalFlow: reimbursementGatewayUrl('/approval-flows/batch'),
  getApprovalFlows: reimbursementGatewayUrl('/approval-flows'),
  createApprovalFlow: reimbursementGatewayUrl('/approval-flows'),
  updateApprovalFlow: reimbursementGatewayUrl('/approval-flows'),
  deleteApprovalFlow: reimbursementGatewayUrl('/approval-flows/:id'),
  getParentCompanyEmployees: reimbursementGatewayUrl(
    '/parent-company/employees'
  ),
  getEmployees: reimbursementGatewayUrl('/employee'),
  getSetting: reimbursementGatewayUrl('/setting'),
  getCustomAttributes: reimbursementGatewayUrl('/custom-attributes'),
  completePayment: reimbursementGatewayUrl('/reimbursements/complete-payment'),
  completePaymentByFile: reimbursementGatewayUrl(
    '/reimbursements/complete-payment/file'
  ),
  getExchangeRates: reimbursementGatewayUrl('/exchange-rates')
};

const accountsApi = {
  downloadBatchTemplate: idpGatewayUrl('/account/batch/template'),
  batchCreate: idpGatewayUrl('/account/batch'),
  batchUpdate: idpGatewayUrl('/account/batch')
};

const productApi = {
  getProductActivationStatus: idpGatewayUrl('/products/status'),
  sendReimbursementPreactivation: idpGatewayUrl(
    '/products/preactivation/reimbursement'
  ),
  sendSpendPreactivation: idpGatewayUrl('/products/preactivation/spend'),
  sendCCCPreactivation: idpGatewayUrl('/products/preactivation/ccc')
};

const jobPositionApi = {
  getJobPositions: idpGatewayUrl('/job-position'),
  getJobLevels: idpGatewayUrl('/job-position/level'),
  updateJobPosition: idpGatewayUrl('/job-position/:id')
};

const organizationApi = {
  getOrganizations: idpGatewayUrl('/organization'),
  updateOrganization: idpGatewayUrl('/organization/:id')
};

const entityApi = {
  getEntities: idpGatewayUrl('/company/entity'),
  getEntityById: idpGatewayUrl('/company/entity/:id'),
  createEntity: idpGatewayUrl('/company/entity'),
  updateEntity: idpGatewayUrl('/company/entity/:id'),
  deleteEntity: idpGatewayUrl('/company/entity/:id')
};

const companyApi = {
  getCompany: idpGatewayUrl('/company'),
  patchCompany: idpGatewayUrl('/company'),
  address: {
    state: idpGatewayUrl('/company/address/state'),
    city: idpGatewayUrl('/company/address/city'),
    district: idpGatewayUrl('/company/address/district')
  }
};

const filesApi = {
  upload: idpGatewayUrl('/files') //POST
};

const bpjsApi = {
  getBpjsSettings: payrollGatewayUrl('/bpjs'),
  getBpjsSettingsValue: payrollGatewayUrl('/company/bpjs'),
  updateCompanyBpjs: idpGatewayUrl('/company/bpjs')
};

const payrollApi = {
  // ***************************** EMPLOYEES *****************************
  getEmployees: payrollGatewayUrl('/employees'),
  getEmployeeByPeriodList: payrollGatewayUrl('/calculation/employees'),

  // ***************************** CONFIGS *****************************
  getPayrollConfigurations: payrollGatewayUrl('/settings/configurations'),
  createPayrollConfiguration: payrollGatewayUrl('/settings/configurations'),
  updatePayrollConfiguration: payrollGatewayUrl('/settings/configurations/:id'),

  // ***************************** COMPONENTS *****************************
  findPayrollComponents: payrollGatewayUrl('/payroll-components'),
  downloadAdjustmentSheetByPayrollComponent: payrollGatewayUrl(
    '/salary-adjustment-components/payroll-components/:id/download'
  ),
  uploadAdjustmentSheetByPayrollComponent: payrollGatewayUrl(
    '/salary-adjustment-components/payroll-components/:id/upload'
  ),
  getPayrollComponentAllowances: payrollGatewayUrl(
    '/salary-components/master-allowances'
  ),
  getPayrollComponentDeductions: payrollGatewayUrl(
    '/salary-components/master-deductions'
  ),
  getPayrollComponentBenefits: payrollGatewayUrl(
    '/salary-components/master-benefits'
  ),
  thrComponents: payrollGatewayUrl('/salary-components/thr'),
  upsertPayrollComponent: payrollGatewayUrl('/salary-components'),
  deletePayrollComponent: payrollGatewayUrl('/salary-components/:id'),

  // ***************************** ADJUSTMENTS *****************************
  getSalaryAdjustments: payrollGatewayUrl('/salary-adjustments'),
  deleteSalaryAdjustment: payrollGatewayUrl('/salary-adjustments/:id'),
  getSalaryAdjustmentComponents: payrollGatewayUrl(
    '/salary-adjustments/:id/salary-adjustment-components'
  ),
  getSalaryAdjustmentComponentsPayrollComponents: payrollGatewayUrl(
    '/salary-adjustment-components/payroll-components/:id'
  ),
  getPublicHoliday: payrollGatewayUrl('/global-settings/public-holidays'),

  // ***************************** REPORT *****************************
  createMonthlyReport: payrollGatewayUrl('/monthly-reports'), // POST
  listMonthlyReport: payrollGatewayUrl('/monthly-reports'), // GET
  getMonthlyReportSummary: payrollGatewayUrl('/monthly-reports/:id/summary'),
  getMonthlyReportSummaryExport: payrollGatewayUrl(
    '/monthly-reports/:id/summary/download'
  ),
  getMonthlyReportSummaryDetailExport: payrollGatewayUrl(
    '/monthly-reports/:id/detail/download'
  ),
  getSptMasaReportDetail: payrollGatewayUrl(
    '/monthly-reports/:id/report/spt-masa'
  ),
  get1721IReport: payrollGatewayUrl('/monthly-reports/:id/report/spt-1721i'),
  getTaxReport: payrollGatewayUrl('/monthly-reports/:id/taxes'),
  getTaxReportExport: payrollGatewayUrl('/monthly-reports/:id/taxes/download'),
  requestBatchSendPayslip: payrollGatewayUrl(
    '/monthly-report-details/payslip-emails'
  ),
  getThrReportDetailExport: payrollGatewayUrl(
    '/monthly-report-details/:id/payslip/download'
  ),
  parseMonthlyReport: payrollGatewayUrl('/monthly-reports/parse'),
  createAdhocMonthlyReport: payrollGatewayUrl('/monthly-reports/adhoc'), // POST
  updateAdhocMonthlyReport: payrollGatewayUrl('/monthly-reports/adhoc/:id'), // PATCH

  // ***************************** DISBURSEMENTS *****************************
  requestDisburse: payrollGatewayUrl('/monthly-reports/:id/disbursement'),
  cancelDisburseRequest: payrollGatewayUrl(
    '/monthly-reports/:id/disbursement/cancel'
  ),
  getReportDisbursementProcessList: payrollGatewayUrl(
    '/monthly-reports/:id/disbursement'
  ),
  requestManualRetryDisbursement: payrollGatewayUrl(
    '/monthly-report-details/disbursement/manual-retry'
  )
};

const jurnalApi = {
  saveJurnalToken: idpGatewayUrl('/jurnal/setup/callback')
};

const accountingApi = {
  getAccountingChartOfAccounts: idpGatewayUrl('/accounting/chart-of-accounts'),
  getAccountingIntegration: idpGatewayUrl('/accounting/integration'),
  requestActivateAccountingIntegration: idpGatewayUrl('/accounting/activate'),
  syncAccountingTransaction: spendUrl('/transactions/accounting/sync'),
  getLastAccountingTransactionSync: spendUrl(
    '/transactions/accounting/last-sync'
  )
};

export default {
  fetchLimitReminder: cccGatewayUrl('/card/:id/limit-reminder'),
  setLimitReminder: cccGatewayUrl('/card/:id/limit-reminder'),
  cards: cccGatewayUrl('/card'),
  cardDetail: cccGatewayUrl('/card/:id'),
  cardsByUserParam: cccGatewayUrl('/account/:id/card'),
  cardsOverview: cccGatewayUrl('/card/overview'),
  cardMaxRequestLimit: cccGatewayUrl('/card/:id/max-request-limit'),
  cardRequestCancel: cccGatewayUrl('/card/:id/cancel/request'),
  cardRequestBlock: cccGatewayUrl('/card/:id/block/request'),
  cardRequestLimit: cccGatewayUrl('/card/:id/limit/request'),
  cardPanAndCvv: cccGatewayUrl('/card/:id/account-data'),
  cardPhysicalActivation: cccGatewayUrl('/card/:id/activate'),
  cardPhysicalActivationToken: cccGatewayUrl('/card/:id/activate/token'),
  cardCreatePhysical: cccGatewayUrl('/card/physical'),
  cardCreateVirtual: cccGatewayUrl('/card/virtual'),
  cardCompanyLimit: cccGatewayUrl('/card/company-limit'),
  cardPaymentProof: cccGatewayUrl('/card/:id/payment-proof'),
  cardholderList: cccGatewayUrl('/cardholder'),
  cardholderDisplayNamePreview: cccGatewayUrl('/cardholder/display-name'),
  cardLimitUsageSummary: gatewayUrl('/corporate-credit-card/card/summary'),
  cardGeneratePaymentLink: cccGatewayUrl('/card/:id/payment-link'),
  scopeRoles: gatewayUrl('/identity-provider/roles/:scope'),
  updateUser: gatewayUrl('/identity-provider/account/:id'),
  removeUser: gatewayUrl('/identity-provider/account/:id'),
  user: gatewayUrl('/identity-provider/account/:id'),
  userList: gatewayUrl('/identity-provider/account'),
  createUser: gatewayUrl('/identity-provider/account'),
  userInfo: gatewayUrl('/identity-provider/account/info'),
  userSwitchCompany: gatewayUrl('/identity-provider/company/switch'),
  banks: cccGatewayUrl('/bank'),
  billings: cccGatewayUrl('/billing'),
  downloadBilling: cccGatewayUrl('/billing/:id/download/:type'),
  billingProof: cccGatewayUrl('/billing/proof'),
  ocr: ocrApi,
  cardApplication: cardApplicationApi,
  cardLimitChange: cardLimitChangeApi,
  cardNotification: cardNotificationApi,
  apolloBalance: apolloBalanceApi,
  spend: spendApi,
  transaction: transactionApi,
  reimbursement: reimbursementApi,
  employee: employeeApi,
  jobPosition: jobPositionApi,
  organization: organizationApi,
  entity: entityApi,
  payroll: payrollApi,
  product: productApi,
  bpjs: bpjsApi,
  company: companyApi,
  files: filesApi,
  jurnal: jurnalApi,
  accounting: accountingApi,
  accounts: accountsApi
};
